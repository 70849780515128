import React, {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ActivitiesServices from "../../api/services/activities";
import logo from '../../assets/images/svg/logo_row.svg';
import "../../styles/views/activityPreview.css";
import {ErrorContext} from "../../context/errorContext";
import {useLoader} from "../../context/loaderContext";
import {Helmet} from 'react-helmet';
import timer from "../../assets/icones/global/timer.svg";
import person from "../../assets/icones/global/users.svg";
import location from "../../assets/icones/global/location.svg";

import appleBadge from "../../assets/images/svg/apple_badge.svg";
import googleBadge from "../../assets/images/svg/google_badge.svg";


const ActivityPreview = () => {

    const {activityId} = useParams();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();


    const [activity, setActivity] = useState(false);

    useEffect(() => {
        setLoading(true);
        ActivitiesServices.getActivityById(activityId).then((response) => {
            setActivity(response.data);
        }).catch((error) => {
            showError("Une erreur est survenue lors de la récupération de l'activité");
        }).finally(() => {
            setLoading(false);
        })
    }, [activityId]);


    const formatDuration = (minutes) => {
        if (minutes < 60) {
            return `${minutes} min`;
        }
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        // Handle pluralization for hours
        const hourLabel = hours === 1 ? 'heure' : 'heures';

        // If there are no remaining minutes, return just hours
        if (remainingMinutes === 0) {
            return `${hours} ${hourLabel}`;
        }

        // Otherwise, return the full string with both hours and minutes
        return `${hours} ${hourLabel} ${remainingMinutes} min`;
    };


    // Déterminer l'image à utiliser pour le OpenGraph
    const getOgImage = () => {
        if (activity && activity.activityImages && activity.activityImages.images && activity.activityImages.images.length > 0) {
            return activity.activityImages.images[0].url;
        }
        // Image par défaut si aucune image d'activité n'est disponible
        return `${window.location.origin}/logo.png`; // Assurez-vous que ce chemin est correct
    };

    return activity && (
        <div className="activity-preview">
            <Helmet>
                <title>{activity.name} - Frimity</title>
                <meta
                    name="description"
                    content={`${activity.name} chez ${activity.partnerID?.name}`}
                />
                <meta
                    property="og:title"
                    content={`${activity.name} - Frimity`}
                />
                <meta
                    property="og:description"
                    content={`${activity.name} au ${activity.partnerID?.name}`}
                />
                <meta
                    property="og:image"
                    content={getOgImage()}
                />
                <meta
                    property="og:url"
                    content={window.location.href}
                />
                <meta
                    property="og:type"
                    content="website"
                />
                {/* Twitter Card data */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${activity.name} - Frimity`} />
                <meta name="twitter:description" content={`${activity.name} chez ${activity.partnerID?.name}`} />
                <meta name="twitter:image" content={getOgImage()} />
            </Helmet>
            <div className="preview-header">
                <img src={logo} alt="logo"/>
            </div>
            <div className="preview-card">
                <div className="activity-card">
                    <div className="activity-card-image">
                        {
                            activity.activityImages.images.length > 1 ?
                                <img src={activity.activityImages.images[0].url} alt="activity"/>
                                :
                                <div className="activity-card-image-single"></div>
                        }
                    </div>
                    <p className={"type_card"}>{activity.type.slice(2)}</p>
                </div>
            </div>
            <div className="preview-content">
                <div className="activity-details">

                    <div className="activity-header">
                        <h1 className={"title-25"} style={{lineHeight: 1.2}}>{activity.name}</h1>
                        <p className={"text-16"} style={{paddingTop: 5}}>proposé par <strong>{activity.partnerID.name}</strong></p>
                    </div>

                    <div className="activity-container">
                        <h2 className={"title-20"}>Infos pratique</h2>
                        <div className="activity-icon-item">
                            <div className="activity-icon">
                                <img src={timer} alt="timer"/>
                            </div>
                            <div className="activity-text">
                                <p className={"text-16"}>{formatDuration(activity.details.duration)}</p>
                                <p className={"text-14 subtitle"}>Temps estimé pour cette activité</p>
                            </div>
                        </div>
                        <div className="activity-icon-item">
                            <div className="activity-icon">
                                <img src={person} alt="person"/>
                            </div>
                            <div className="activity-text">
                                <p className={"text-16"}>À partir
                                    de {activity.details.minParticipants} jusqu'à {activity.details.maxParticipants} personnes</p>
                                <p className={"text-14 subtitle"}>Activité de groupe</p>
                            </div>
                        </div>
                        <div className="activity-icon-item">
                            <div className="activity-icon">
                                <img src={location} alt="location"/>
                            </div>
                            <div className="activity-text">
                                <p className={"text-16"}>{activity.details.location}</p>
                                <p className={"text-14 subtitle"}>Adresse de l'activité</p>
                            </div>
                        </div>
                    </div>

                    <div className="activity-container">
                        <h2 className={"title-20"}>Description</h2>
                        <p>{activity.details.description}</p>
                    </div>
                </div>
                <div className="preview-footer">
                    <p className={"text-18 bold"}>Télécharge maintenant Frimity</p>
                    <div className="footer-links">
                        <a href={process.env.REACT_APP_APP_APPLE_URL} className={"badge"}>
                            <img src={appleBadge} alt="apple-badge"/>
                        </a>
                        <a href={process.env.REACT_APP_APP_ANDROID_URL} className={"badge"}>
                            <img src={googleBadge} alt="google-badge"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default ActivityPreview;