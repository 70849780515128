import React, {useContext, useEffect, useState} from 'react';
import HeaderView from "../../components/header/headerView";
import IconButton from "../../components/button/iconButton";
import TabBar from "../../components/navbar/tabBar";
import Tag from "../../components/tag/tag";
import download from '../../assets/icones/global/download.svg';
import Form from "../../components/form/form";
import Company from "./formPartner/company";
import Personal from "./formPartner/personal";
import LegalDocument from "./formPartner/legalDocument";
import '../../styles/views/singlePartner.css';
import {useParams} from "react-router";
import PartnersServices from "../../api/services/partners";
import useStatusName from "../../hooks/parseText/useStatusName";
import useParsePartner from "../../hooks/parseDataApi/useParsePartner";
import useFormValidation from "../../hooks/form/useFormValidation";
import useFormState from "../../hooks/form/useFormUpdate";
import PartnersDocuments from "../../api/services/partnersDocuments";
import trash from '../../assets/icones/global/trash_can_red.svg';
import check from "../../assets/icones/global/check_stroke.svg";
import {useDispatch, useSelector} from "react-redux";
import {ErrorContext} from "../../context/errorContext";
import {LoaderContext, useLoader} from "../../context/loaderContext";
import ModalDeleting from "../../components/modal/modalDeleting/modalDeleting";
import {useNavigate} from "react-router-dom";
import Booking from "./formPartner/booking";
import {usePartner} from "../../context/partnerContext";
import {userPartnerUpdateEngineID} from "../../redux/slice/authSlice";
import useVerificationHandlers from "../../hooks/verification/entitiesVerification";
import SocialNetworks from "./formPartner/socialNetworks";
import PartnersSocialNetworks from "../../api/services/partnersSocialNetworks";


const SinglePartner = () => {

    //get user from redux state
    const partnerId = useParams().partnerId;
    const [refresh, setRefresh] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {parsePartner, parsePartnerForUpdate} = useParsePartner();
    const { validateForm } = useFormValidation();
    const { getStatusName } = useStatusName();
    const { showError } = useContext(ErrorContext);
    const { setLoading } = useLoader();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { handlePartnersVerification } = useVerificationHandlers();


    //get user from redux state
    const { currentPartnerId, allPartners } = usePartner();
    const user = useSelector(state => state.auth.user);
    const numberActivity = !currentPartnerId ? 0 : user && user.role === "partner" ?
        useSelector((state) => state.auth.user.partnerID.find(partner => partner._id === currentPartnerId).activityID.length)
        :
        allPartners.length > 0 && allPartners.find(partner => partner._id === currentPartnerId).activityID.length;

    const { formState, setFormState, handleFormChange, getFormChanges } = useFormState(parsePartner([]));

    useEffect(() => {
        setLoading(true);
        const partnerID = partnerId || currentPartnerId?._id;
        PartnersServices.getPartnerById(partnerID)
            .then((response) => {
                if (response.status === 200) {
                    const parsedPartner = parsePartner(response.data);
                    setFormState(parsedPartner);
                } else {
                    setFormState(parsePartner([]));
                }
            })
            .catch((error) => {
                showError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [refresh]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate form
        const formIsValid = validateForm(formState, setFormState);
        if (!formIsValid.isFormValid) {
            return;
        }

        try {
            setLoading(true);

            // Prepare data
            const newPartnerChanges = getFormChanges();
            const partnerForApi = parsePartnerForUpdate(newPartnerChanges);
            const parsePartnerFiles = new FormData();

            // Process legal documents
            const localDocuments = partnerForApi.legalInfoID?.legalDocuments?.filter(doc => doc.isLocal) || [];
            if (localDocuments.length > 0) {
                await appendFilesToFormData(localDocuments, parsePartnerFiles, 'documents', async (doc) => {
                    const blob = await fetch(doc.src).then(res => {
                        if (!res.ok) throw new Error(`Failed to fetch document: ${res.status}`);
                        return res.blob();
                    });
                    return new File([blob], doc.name, { type: doc.type });
                });
            }

            // Process social network logos
            const socialNetworks = partnerForApi.partnerSocialNetworksID?.logo?.filter(doc => doc.isLocal) || [];
            if (socialNetworks.length > 0) {
                await appendFilesToFormData(socialNetworks, parsePartnerFiles, 'logo', async (doc) => {
                    const blob = await fetch(doc.preview).then(res => {
                        if (!res.ok) throw new Error(`Failed to fetch logo: ${res.status}`);
                        return res.blob();
                    });
                    return new File([blob], doc.name, { type: 'image/webp' });
                });

                // Log FormData entries if needed
                if (process.env.NODE_ENV === 'development') {
                    for (let [key, value] of parsePartnerFiles.entries()) {
                    }
                }
            }

            // Clean up data before sending
            if (partnerForApi.legalInfoID) {
                delete partnerForApi.legalInfoID.legalDocuments;
            }

            if (partnerForApi.partnerSocialNetworksID) {
                delete partnerForApi.partnerSocialNetworksID.logo;
            }

            // Update partner
            const response = await PartnersServices.updatePartner(partnerId, partnerForApi);

            if (response.status === 200) {

                // Handle file uploads
                if (parsePartnerFiles.has('documents')) {
                    await PartnersDocuments.create(partnerId, parsePartnerFiles);
                }

                if (parsePartnerFiles.has('logo')) {
                    await PartnersSocialNetworks.create(partnerId, parsePartnerFiles);
                }

                // Update Redux state if engineID changed
                if (partnerForApi.engineID) {
                    dispatch(userPartnerUpdateEngineID({
                        partnerID: partnerId,
                        engineID: partnerForApi.engineID
                    }));
                }

                // Complete the process
                setRefresh(true);
                window.scrollTo(0, 0);
                setTimeout(() => setLoading(false), 1000);
            }
        } catch (error) {
            showError(error);
        } finally {
            setLoading(false);
            setRefresh(true);
        }
    };

// Helper function to append files to FormData
    const appendFilesToFormData = async (items, formData, fieldName, fileProcessor) => {
        if (!items || items.length === 0) return;

        for (const item of items) {
            try {
                const file = await fileProcessor(item);
                formData.append(fieldName, file);
            } catch (error) {
                console.error(`Error processing ${fieldName}:`, error);
                throw error;
            }
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        setLoading(true);
        await PartnersServices.deletePartner(partnerId).then((response) => {
            if (response.status === 200) {
                setRefresh(true);
                setIsModalOpen(false);
                navigate('/partners');
                setLoading(false);
            }
        }).catch((error) => {
            showError(error);
        });
    }

    return(
        <div className={"main-container is__grey"}>
            <HeaderView title={"Mon espace partenaire"} actions={
                <>
                    {
                        user && user.role === "admin" &&
                        <IconButton icon={download} text={"Exporter la fiche du partenaire"} isPrimary={false} />
                    }
                </>
            }
            isGoBack={user && user.role === "partner" || user.role === "partners-admin"? false : true} />

            <TabBar
                tabs={[
                    {
                        name: "Fiche du partenaire",
                        link: `/partners/${partnerId}`
                    },
                    {
                        name: "Liste d’activités",
                        link: `/partners/${partnerId}/activities`,
                        index: numberActivity ? numberActivity : 0
                    },
                    {
                        name: "Gérer vos offres",
                        link: `/partners/${partnerId}/offers`,
                    },
                    {
                        name: "Utilisateurs",
                        link: `/partners/${partnerId}/users`,
                    },
                    {
                        name: "Plan tarifaire",
                        link: `/partners/${partnerId}/subscription`,
                    }
                ]}
                activeTab={0}
            />


            <div className={"header__container"}>
                <h2 className={"title-20"}>Informations sur votre établissement chez Frimity</h2>
                <p className={"text-14"}>Vous pouvez modifier ici les informations de votre établissement </p>
            </div>

            <div className={"main-infos__container"}>
                <div className={"main-infos__container__col"}>
                    <Tag text={getStatusName(formState.status)} status={formState.status} />
                    <h2 className={"title-25"}>{formState.company.name ? formState.company.name.value : "Nouveau partenaire"}</h2>
                </div>
                <div className={"main-infos__container__row"}>
                    {user && (user.role === "admin" || user.role === "super-admin") && <IconButton icon={trash} text={"Supprimer le partenaire"} isRed={true} onClick={() => setIsModalOpen(true)} />}
                    {user && (user.role === "admin" || user.role === "super-admin") && formState.status === "pending" && <IconButton icon={check} text={"Vérifier le partenaire"} isPrimary={true} onClick={() => handlePartnersVerification(partnerId, "active", setRefresh)} />}
                </div>
            </div>

            <Form
                title={"Informations sur le partenaire"}
                children={
                    formState && formState.company !== {} && formState.personal !== {} && formState.legalDocuments !== {} && formState.bank !== {} &&
                        <>
                            <Company setPartner={handleFormChange} company={formState.company} user={user} />
                            <SocialNetworks setPartner={handleFormChange} socialNetworks={formState.socialNetworks} partnerId={partnerId}/>
                            <Booking setPartner={handleFormChange} booking={formState.booking} />
                            <Personal mode={"edit"} setPartner={handleFormChange} personal={formState.personal} />
                            <LegalDocument setPartner={handleFormChange} documents={formState.legalDocuments} type={"remote"} partnerID={partnerId} />
                        </>
                 }
                mode={"edit"}
                onSubmit={handleSubmit}
            />

            <ModalDeleting
                itemName={"ce partenaire"}
                deleteItem={(e) => handleDelete(e)}
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
            />
        </div>
    )

}

export default SinglePartner;