//all the request to the api will be done here
import axios from 'axios'
import {apiConfig} from '../config/apiconfig'
import {store} from '../../redux/store'

export const httpClient = axios.create({
    baseURL: apiConfig.baseURL,
    timeout: apiConfig.timeout,
    headers: apiConfig.headers
});


httpClient.interceptors.request.use(
    (config) => {
        // Check if we need to force a public token
        if (config.usePublicToken) {
            config.headers.Authorization = `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`;
            delete config.usePublicToken;
        } else {
            // Normal token lookup: use auth or sign-in token if available,
            // otherwise fallback to the public token.
            const authToken = store.getState().auth.token;
            const signInToken = store.getState().signIn.token;

            if (authToken) {
                config.headers.Authorization = `Bearer ${authToken}`;
            } else if (signInToken) {
                config.headers.Authorization = `Bearer ${signInToken}`;
            } else if (process.env.REACT_APP_PUBLIC_API_KEY) {
                config.headers.Authorization = `Bearer ${process.env.REACT_APP_PUBLIC_API_KEY}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export const getRequest = async (url, params = {}, usePublicToken ) => {
    try {
        const config = { params };
        // If the public token should be used, set the flag
        if (usePublicToken) {
            config.usePublicToken = true;
        }
        const response = await httpClient.get(`${process.env.REACT_APP_API_URL}${url}`, config);
        return { data: response.data, status: response.status };
    } catch (error) {
        throw error.response;
    }
};

export const postRequestJson = async (url, body) => {
    try {
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}${url}`, body);
        return { data: response.data, status: response.status };
    } catch (error) {
        throw error.response.data.error;
    }
};

export const postRequestFormData = async (url, body, config = {}) => {
    try{
        const response = await httpClient.post(`${process.env.REACT_APP_API_URL}` + url, body, {
            headers: { 'Content-Type': 'multipart/form-data' },
            ...config, // Add any additional configurations passed in
        });
        return {data: response.data, status: response.status};
    }catch (error) {
        throw error.response.data.error;
    }
}


export const deleteRequest = async (url) => {
    try{
        const response = await httpClient.delete(`${process.env.REACT_APP_API_URL}` + url);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}

export const patchRequest = async (url, body) => {
    try{
        const response = await httpClient.patch(`${process.env.REACT_APP_API_URL}` + url, body);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}

//post request with no token
export const postRequestJsonNotToken = async (url, data) => {
    try{
        const response = await axios.post(`${process.env.REACT_APP_API_URL}` + url, data);
        return {data: response.data, status: response.status};
    }catch (error){
        throw error.response.data.error;
    }
}



