import React, { useState, useEffect, useMemo, useCallback } from 'react';
import HeaderView from "../../components/header/headerView";
import '../../styles/views/partners.css';
import AnalyticsSection from "../../components/analytics/analyticsSection";
import BookingSection from "../../components/analytics/bookingSection";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import AnalyticsServices from '../../api/services/analytics';
import InputSelect from "../../components/form/inputSelect/inputSelect";
import calendarIcon from "../../assets/icones/global/calendar.svg";
import IconButton from "../../components/button/iconButton";
import SingleDateRangePicker from "../../components/calendar/singleView/singleDateRangePicker";

// Updated helper function to process the raw data from the API response
// Updated processData: groups booking actions under result.booking
const processData = (data) => {
    const result = {
        booking: {
            "booking-booked": [],
            "booking-cancelled": [],
            "booking-completed": []
        },
        call: [],
        mapClick: [],
        websiteClick: []
    };

    data.forEach(item => {
        const date = new Date(item._id.date).toISOString(); // Preserve full date and time
        const actionType = item._id.actionType;
        // For booking actions, push into the nested booking object
        if (actionType.startsWith("booking-") && result.booking[actionType]) {
            result.booking[actionType].push({
                date,
                count: item.count
            });
        } else if (result[actionType]) {
            // For non-booking actions, push directly
            result[actionType].push({
                date,
                count: item.count
            });
        }
    });

    return result;
};

// Updated aggregateCounts: sums nested booking counts as well as top-level counts
const aggregateCounts = (data) => {
    const aggregated = {
        booking: {
            "booking-booked": 0,
            "booking-cancelled": 0,
            "booking-completed": 0
        },
        call: 0,
        mapClick: 0,
        websiteClick: 0
    };

    if (data && typeof data === 'object') {
        // Aggregate booking actions
        if (data.booking && typeof data.booking === 'object') {
            Object.keys(aggregated.booking).forEach(key => {
                if (Array.isArray(data.booking[key])) {
                    data.booking[key].forEach(item => {
                        aggregated.booking[key] += item.count;
                    });
                }
            });
        }
        // Aggregate other actions
        ['call', 'mapClick', 'websiteClick'].forEach(key => {
            if (Array.isArray(data[key])) {
                data[key].forEach(item => {
                    aggregated[key] += item.count;
                });
            }
        });
    }

    return aggregated;
};

// Helper function to get the start and end dates based on the selected period
const getDateRange = (period) => {
    const today = new Date();
    let startDate, endDate;

    switch (period) {
        case 'yesterday': {
            // Calculate yesterday in UTC by using Date.UTC
            const yesterdayLocal = new Date(today);
            yesterdayLocal.setDate(today.getDate() - 1);
            yesterdayLocal.setHours(0, 0, 0, 0);
            const startUTC = new Date(Date.UTC(
                yesterdayLocal.getFullYear(),
                yesterdayLocal.getMonth(),
                yesterdayLocal.getDate(),
                0, 0, 0, 0
            ));
            const endUTC = new Date(Date.UTC(
                yesterdayLocal.getFullYear(),
                yesterdayLocal.getMonth(),
                yesterdayLocal.getDate(),
                23, 59, 59, 999
            ));
            startDate = startUTC.toISOString();
            endDate = endUTC.toISOString();
            break;
        }
        case 'weekly': {
            // Determine Monday and Sunday in local time first
            const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
            const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
            const localMonday = new Date(today);
            localMonday.setDate(today.getDate() - diffToMonday);
            localMonday.setHours(0, 0, 0, 0);
            const localSunday = new Date(localMonday);
            localSunday.setDate(localMonday.getDate() + 6);
            localSunday.setHours(23, 59, 59, 999);

            // Now convert these boundaries to UTC using Date.UTC
            const mondayUTC = new Date(Date.UTC(
                localMonday.getFullYear(),
                localMonday.getMonth(),
                localMonday.getDate(),
                0, 0, 0, 0
            ));
            const sundayUTC = new Date(Date.UTC(
                localSunday.getFullYear(),
                localSunday.getMonth(),
                localSunday.getDate(),
                23, 59, 59, 999
            ));
            startDate = mondayUTC.toISOString();
            endDate = sundayUTC.toISOString();
            break;
        }
        case 'monthly': {
            // Calculate the start and end of the current month in UTC
            const start = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0));
            // Note: For end, using day 0 of the next month gives the last day of current month.
            const end = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999));
            startDate = start.toISOString();
            endDate = end.toISOString();
            break;
        }
        case 'quarterly': {
            // Quarterly: determine the current quarter's start and end.
            const currentMonth = today.getMonth();
            const quarterStartMonth = Math.floor(currentMonth / 3) * 3;
            const start = new Date(today.getFullYear(), quarterStartMonth, 1);
            start.setHours(0, 0, 0, 0);
            const end = new Date(today.getFullYear(), quarterStartMonth + 3, 0);
            end.setHours(23, 59, 59, 999);
            startDate = start.toISOString();
            endDate = end.toISOString();
            break;
        }
        case 'yearly': {
            // Yearly: from January 1st at 00:00:00.000 to December 31st at 23:59:59.999 of the current year.
            const start = new Date(today.getFullYear(), 0, 1);
            start.setHours(0, 0, 0, 0);
            const end = new Date(today.getFullYear(), 11, 31);
            end.setHours(23, 59, 59, 999);
            startDate = start.toISOString();
            endDate = end.toISOString();
            break;
        }
        case 'lastYear': {
            // Last year: from January 1st to December 31st of the previous year.
            const start = new Date(today.getFullYear() - 1, 0, 1);
            start.setHours(0, 0, 0, 0);
            const end = new Date(today.getFullYear() - 1, 11, 31);
            end.setHours(23, 59, 59, 999);
            startDate = start.toISOString();
            endDate = end.toISOString();
            break;
        }
        default: {
            // Fallback: use today's date
            startDate = today.toISOString();
            endDate = today.toISOString();
            break;
        }
    }

    return { startDate, endDate };
};

// Helper function to calculate the previous date range based on the current range
const calculatePreviousDateRange = (currentRange) => {
    const currentStart = new Date(currentRange.startDate);
    const currentEnd = new Date(currentRange.endDate);

    if (isNaN(currentStart.getTime()) || isNaN(currentEnd.getTime())) {
        return { startDate: null, endDate: null };
    }

    const daysDiff = Math.ceil((currentEnd - currentStart) / (1000 * 60 * 60 * 24));

    const previousStart = new Date(currentStart);
    previousStart.setDate(currentStart.getDate() - daysDiff);

    const previousEnd = new Date(currentEnd);
    previousEnd.setDate(currentEnd.getDate() - daysDiff);

    return {
        startDate: previousStart.toISOString(),
        endDate: previousEnd.toISOString()
    };
};

const Analytics = () => {
    const { partnerId } = useParams();
    const [analyticsData, setAnalyticsData] = useState({});
    const [previousAnalyticsData, setPreviousAnalyticsData] = useState({});
    const user = useSelector((state) => state.auth.user);

    const [openCalendar, setOpenCalendar] = useState(false);
    // Default is "monthly" to show "this month" values
    const [selectedPeriod, setSelectedPeriod] = useState('monthly');
    const [customDateRange, setCustomDateRange] = useState(null);

    // When no custom range is selected, default to "this month"
    const currentFilter = useMemo(() => customDateRange || getDateRange(selectedPeriod), [selectedPeriod, customDateRange]);
    const previousFilter = useMemo(() => calculatePreviousDateRange(currentFilter), [currentFilter]);

    const fetchAnalytics = useCallback(async () => {
        try {
            const currentData = await AnalyticsServices.getAnalytics({ partnerId, ...currentFilter });
            const processedCurrentData = processData(currentData.data);
            setAnalyticsData(processedCurrentData);

            const previousData = await AnalyticsServices.getAnalytics({ partnerId, ...previousFilter });
            const processedPreviousData = processData(previousData.data);
            setPreviousAnalyticsData(processedPreviousData);
        } catch (error) {
            console.error('Failed to fetch analytics data', error);
        }
    }, [partnerId, currentFilter, previousFilter]);

    useEffect(() => {
        fetchAnalytics();
    }, [partnerId, selectedPeriod]);


    return (
        <div className="main-container">
            <HeaderView
                title="Analyse de vos données"
                isGoBack={false}
                actions={
                    <>
                        <div className="analytics__filters">
                            <div className={`analytics__filters__calendar ${openCalendar ? "open" : ""}`}>
                                <SingleDateRangePicker
                                    disabled={false}
                                    setDateSelected={(startDate, endDate) => {
                                        setCustomDateRange({ startDate, endDate });
                                        setOpenCalendar(false);
                                    }}
                                    arrayDates={[]}
                                    arrayDays={[]}
                                    isBooking={false}
                                    title="Période personnalisée"
                                    currentDateCard={false}
                                    disabledPastDates={false}
                                />
                            </div>
                        </div>
                        <InputSelect
                            options={[
                                { name: "Hier", value: "yesterday" },
                                { name: "Cette semaine", value: "weekly" },
                                { name: "Ce mois", value: "monthly" },
                                { name: "Ce trimestre", value: "quarterly" },
                                { name: "Cette année", value: "yearly" },
                                { name: "L'année dernière", value: "lastYear" },
                            ]}
                            placeholder="Sélectionner une période"
                            valueSelected={selectedPeriod}
                            value={selectedPeriod}
                            onChange={(e) => {
                                setSelectedPeriod(e.target.value);
                                setCustomDateRange(null);
                            }}
                            isColumn={true}
                        />
                    </>
                }
            />
            <AnalyticsSection
                analyticsData={analyticsData}
                previousAnalyticsData={previousAnalyticsData}
            />
            <BookingSection
                previousBookingData={previousAnalyticsData.booking?.['booking-booked'] || []}
                bookingData={analyticsData.booking?.['booking-booked'] || []}
                selectedPeriod={selectedPeriod}
                bookingAnalyse={analyticsData.booking}
            />
        </div>
    );
};

export default Analytics;