import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js/auto';

const LineChartComponent = ({ title, labels, data }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);


    useEffect(() => {
        Chart.defaults.font.family = 'Poppins, sans-serif'; // Set the font family
        Chart.defaults.font.size = 12; // Set the font size
        Chart.defaults.font.weight = 200; // Set the font weight
        Chart.defaults.font.color = '#9A9FA5'; // Set the font color

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');

        // Determine the min and max values in the data
        const minValue = Math.min(...data);
        const maxValue = Math.max(...data);

        // Determine if all values are zero
        const allValuesAreZero = data.every(value => value === 0);

        // Determine if the range is large enough to start from zero
        const range = maxValue - minValue;
        const beginAtZero = range < maxValue * 0.1 && !allValuesAreZero;

        // Ensure the minimum value is not negative and set a fixed max if all values are zero
        const suggestedMin = allValuesAreZero ? 0 : Math.max(0, minValue - range * 0.1);
        const suggestedMax = allValuesAreZero ? 200 : maxValue + range * 0.1;

        chartInstance.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    data: data,
                    fill: false,
                    backgroundColor: '#040320',
                    tension: 0.5,
                    pointStyle: 'circle',
                    pointBorderWidth: 10,
                    pointRadius: function(context) {
                        return context.raw > 0 ? 5 : 0; // Show points only if value is greater than 0
                    },
                    pointHoverRadius: 9,
                    pointCursor: 'pointer',
                    pointBorderColor: '#FECE2F',
                    pointBackgroundColor: '#FECE2F',
                    borderColor: '#040320',
                    borderWidth: 6,
                }],
            },
            options: {
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: beginAtZero, // Dynamically set based on data range
                        suggestedMin: suggestedMin,
                        suggestedMax: suggestedMax,
                        grid: {
                            display: true, // Sets whether grid lines are shown across the chart
                            drawBorder: false,
                            color: 'rgba(0, 0, 0, 0.1)', // Sets the color of the grid lines
                        },
                        ticks: {
                            autoSkip: false,  // Do not skip any labels
                            color: '#9A9FA5', // Sets the color of the tick labels
                            font: {
                                size: 12, // Sets the font size of the tick labels
                                weight: 'lighter', // Sets the font weight of the tick labels
                                family: 'Poppins, sans-serif', // Sets the font family of the tick labels
                            },
                            stepSize: allValuesAreZero ? 50 : Math.ceil(range / 5), // Set a step size based on the data range or fixed if all values are zero
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            autoSkip: false,  // Do not skip any labels
                            color: '#9A9FA5', // Sets the color of the tick labels
                            font: {
                                size: 12, // Sets the font size of the tick labels
                                weight: 'lighter', // Sets the font weight of the tick labels
                                family: 'Poppins, sans-serif', // Sets the font family of the tick labels
                            },
                            padding: 20 // Adds padding between the tick labels and the axis
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false, // Disable the default tooltip
                        external: function(context) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            const tooltipModel = context.tooltip;
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const titleLines = tooltipModel.title || [];
                                const bodyLines = tooltipModel.body.map(getBody);

                                let innerHtml = '<thead style="background: none; padding: 0; text-decoration: none;border: 0;">';

                                titleLines.forEach(function() {
                                    innerHtml += '<tr style="background: none; padding: 0; border: 0;"><th style=" border: 0;text-decoration: none;;color: #FFFFFF; font-size: 14px; font-weight: 600;">Réservations</th></tr>';

                                });
                                innerHtml += '</thead><tbody style="display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 10px;">';

                                bodyLines.forEach(function(body, i) {
                                    const span = '<span style="width: 12px;height: 12px;border-radius: 44px;background: #FECE2F;"></span>';
                                    innerHtml += '<tr style="text-align: left; padding: 5px 0 0 0;"><td style="color: #FFFFFF; font-size: 14px; font-weight: 500; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; gap: 10px;">' + span + body + '</tdstyle></tr>';
                                });
                                innerHtml += '</tbody>';

                                let tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            const position = context.chart.canvas.getBoundingClientRect();
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 20 + 'px';
                            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel.options.bodyFont.family;
                            tooltipEl.style.fontSize = tooltipModel.options.bodyFont.size + 'px';
                            tooltipEl.style.color = 'white';
                            tooltipEl.style.textAlign = 'left';
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.borderRadius = '8px';
                            tooltipEl.style.cursor = 'pointer';
                            tooltipEl.style.border = '1px solid rgba(255, 255, 255, 0.12)';
                            tooltipEl.style.background = '#272B30';
                            tooltipEl.style.boxShadow = '0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 0px 1px 0px #000 inset, 0px 4px 8px 0px rgba(0, 0, 0, 0.10)';
                            tooltipEl.style.pointerEvents = 'none';
                            tooltipEl.style.display = 'flex';
                            tooltipEl.style.alignItems = 'flex-start';
                            tooltipEl.style.justifyContent = 'flex-start';
                            tooltipEl.style.flexDirection = 'column';
                            tooltipEl.style.gap = '8px';
                            tooltipEl.style.padding = '8px';

                        }
                    }
                }
            }
        });

        return () => {
            chartInstance.current.destroy();
        };
    }, [labels, data]);

    return <canvas ref={chartRef} />;
};

export default LineChartComponent;
