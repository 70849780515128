import React, { createContext, useState, useContext, useEffect } from 'react';
import PartnersServices from '../api/services/partners';
import {useSelector} from "react-redux";

const PartnerContext = createContext();

export const PartnerProvider = ({ children }) => {
    const [currentPartnerId, setCurrentPartnerId] = useState(() => localStorage.getItem('currentPartnerId') || null);
    const [allPartners, setAllPartners] = useState([]);

    // Fetch all partners for super-admin or multi-partner users
    const fetchAllPartners = async () => {
        try {
            const response = await PartnersServices.getAllPartners();
            if (response.status === 200) {
                setAllPartners(response.data);
            }
        } catch (error) {
            console.error("Error fetching partners:", error);
        }
    };


    // Set a partner ID
    const setPartnerId = (partnerId) => {
        if (partnerId) {
            localStorage.setItem('currentPartnerId', partnerId);
            setCurrentPartnerId(partnerId);
        }
    };

    // Clear partner ID
    const clearPartnerId = () => {
        localStorage.removeItem('currentPartnerId');
        setCurrentPartnerId(null);
    };

    const callAllPartners = async () => {
        try {
            const response = await PartnersServices.getAllPartners();
            if (response.status === 200) {
                setAllPartners(response.data);
            }
        } catch (error) {
            console.error("Error fetching partners:", error);
        }
    };

    useEffect(() => {
        if(localStorage.getItem('isLogged') === 'true'){
            fetchAllPartners();
        }
    }, [currentPartnerId]);

    return (
        <PartnerContext.Provider value={{ currentPartnerId, setPartnerId, clearPartnerId, allPartners, callAllPartners }}>
            {children}
        </PartnerContext.Provider>
    );
};

export const usePartner = () => useContext(PartnerContext);

