import {useContext, useEffect, useRef, useState} from 'react';
import ActivitiesImages from "../../api/services/activitiesImages";
import {ErrorContext} from "../../context/errorContext";
import PartnersSocialNetworks from "../../api/services/partnersSocialNetworks";

function useImagesHandler({ imagesFiles, onChange, type, useCase }) {
    const [images, setImages] = useState(imagesFiles || []);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(type === "remote"); // Initialize loading state based on type
    const hiddenInputRef = useRef(null);
    const { showError } = useContext(ErrorContext);

    // Helper function to convert bytes to KB
    const bytesToKB = (bytes) => (bytes / 1024).toFixed(2);


    // Function to create an image object from a File/Blob or remote URL
    const createImageObject = (image, src, isLocal = false) => {
        return {
            name: image.name || image.metadata?.sanitizedName ? image.metadata.sanitizedName : image.metadata?.originalName,
            size: bytesToKB(image.size || image.metadata?.size),
            type: image.type || 'image/*',
            src: src || image.url,
            preview: src || image.url,
            isLocal: isLocal,
        };
    };


    // Handler for new file input change
    const handleChange = (e) => {
        setIsLoading(true); // Set loading state to true

        // Filter and process the newly selected files
        const newLocalImages = Array.from(e.target.files).filter(file => {
            // Check if the file name already exists in the current state
            const isDuplicate = images.some(image => image.name === file.name);
            if (isDuplicate) {
                alert('Le fichier existe déjà, vous ne pouvez pas ajouter le même fichier.');
                return false; // Exclude this file
            }
            return true; // Include this file
        }).map(imageFile => {
            // Only create a URL if the imageFile is a valid File object
            if (imageFile instanceof File) {
                const src = URL.createObjectURL(imageFile);
                return createImageObject(imageFile, src, true); // Mark as local
            } else {
                return null; // Exclude invalid objects
            }
        }).filter(image => image !== null); // Remove any null values

        if (newLocalImages.length === 0) {
            setIsLoading(false); // Reset loading state if no valid images
            return;
        }

        // Update state with new images
        setImages(prevImages => {
            const updatedImages = [...prevImages, ...newLocalImages];
            onChange(updatedImages);
            setIsLoading(false); // Reset loading state after images are updated
            return updatedImages;
        });

        resetInput();
    };

    // Function to add already compressed images returned from the server
    // Function to add already compressed images returned from the server
    const addCompressedImages = (compressedImages) => {
        // Filter out invalid images
        const validCompressedImages = compressedImages
            .map((image) => {
                if (!image.preview || !image.name) {
                    console.warn("Invalid compressed image object:", image);
                    return null;
                }
                return {
                    ...image,
                    preview: image.preview,
                    name: image.name,
                    size: image.size || "0.00",
                    isLocal: true,
                };
            })
            .filter((image) => image !== null);

        setImages((prevImages) => {
            const updatedImages = [...prevImages, ...validCompressedImages];
            onChange(updatedImages); // Trigger external callback
            return updatedImages;
        });
    };





    // Effect for initializing remote images
    useEffect(() => {
        if (!isInitialized) {
            if (type === "remote" && Array.isArray(imagesFiles) && imagesFiles.length > 0) {
                const shouldInitialize = images.length === 0 || images.some(
                    (image) => !imagesFiles.some((file) => file.name === image.name)
                );

                if (shouldInitialize) {
                    const initialImages = imagesFiles.map((file) =>
                        createImageObject(file, file.url, !file.metadata)
                    );
                    setImages(initialImages);
                    setIsInitialized(true);
                } else {
                }
            } else if (type === "remote-logo" && imagesFiles?.url) {
                const initialImage = createImageObject(imagesFiles, imagesFiles.url, !imagesFiles.metadata);

                setImages([initialImage]);

                setIsInitialized(true);
            }
            setIsLoading(false);
        }
    }, [imagesFiles, type]);


    // Function to reset the file input value
    const resetInput = () => {
        hiddenInputRef.current.value = '';
    };

    // Function to delete an image
    const deleteImage = async (activityId, index, partnerId) => {
        setImages((prevImages) => {
            const imageToDelete = prevImages[index];

            if (!imageToDelete) {
                console.warn("Image not found in state, skipping deletion.");
                return prevImages;
            }

            // Prevent multiple delete requests for the same image
            if (imageToDelete.isDeleting) {
                console.warn("Deletion already in progress for this image:", imageToDelete.name);
                return prevImages;
            }

            // Mark the image as being deleted
            return prevImages.map((image, i) =>
                i === index ? { ...image, isDeleting: true } : image
            );
        });

        try {
            // Choose the appropriate API call
            const deleteApiCall =
                useCase === "socialNetworks"
                    ? PartnersSocialNetworks.delete(partnerId, images[index].name)
                    : ActivitiesImages.delete(activityId, images[index].name, partnerId);

            const response = await deleteApiCall;

            if (response.status === 200) {
                // Update state to remove the deleted image
                setImages((prevImages) =>
                    prevImages.filter((_, i) => i !== index)
                );

                onChange(images.filter((_, i) => i !== index));
            } else {
                console.error(`Failed to delete image: ${response.status}`);
                throw new Error("Image deletion failed on the server.");
            }
        } catch (error) {
            console.error("Error deleting image from server:", error);

            // Revert the deletion state if an error occurs
            setImages((prevImages) =>
                prevImages.map((image) =>
                    image.name === images[index].name ? { ...image, isDeleting: false } : image
                )
            );
        }
    };

    return { images, handleChange, deleteImage, hiddenInputRef, resetInput, addCompressedImages, isLoading };
}

export default useImagesHandler;
