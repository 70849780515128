
import { toZonedTime } from 'date-fns-tz';
import { parseISO, differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths } from 'date-fns';


const useParseDate = () => {
    // Returns a date string in the format YYYY-MM-DD using the UTC values.
    const parseDate = (dateString) => {
        if (!dateString) return null;
        const timeZone = 'Europe/Paris';
        const date =  toZonedTime(new Date(dateString), timeZone);
        const options = {
            timeZone: 'UTC', // Use UTC to avoid any conversion
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        };
        // Using formatToParts to build the YYYY-MM-DD string
        const formatter = new Intl.DateTimeFormat('fr-FR', options);
        const parts = formatter.formatToParts(date);
        const year = parts.find(part => part.type === 'year').value;
        const month = parts.find(part => part.type === 'month').value;
        const day = parts.find(part => part.type === 'day').value;
        return `${year}-${month}-${day}`;
    };

    // Format a full booking date (date and time) using UTC so the time isn't shifted.
    const parseBookingDate = (dateString) => {
        const timeZone = 'Europe/Paris';

        // Convertir la date de réservation au fuseau horaire de Paris
        const bookingDate = toZonedTime(new Date(dateString), timeZone);

        // Formater la date et l'heure
        const options = {
            timeZone: timeZone,
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };
        return new Intl.DateTimeFormat('fr-FR', options).format(bookingDate);
    };

    // Format only the date (without time) using UTC.
    const parseBookingDateDay = (dateString) => {
        const timeZone = 'Europe/Paris';
        const date = toZonedTime(new Date(dateString), timeZone);
        const options = {
            timeZone: 'UTC', // use UTC to avoid conversion
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        };
        return new Intl.DateTimeFormat('fr-FR', options).format(date);
    };

    // Format a slot time from a start time and a duration (in minutes) using UTC.
    const parseSlotTimeBooking = (dateString, durationBooking) => {
        const timeZone = 'Europe/Paris';
        const date = toZonedTime(new Date(dateString), timeZone);
        const dateEnd = new Date(date.getTime() + durationBooking * 60 * 1000);

        // Formater la date et l'heure
        const options = {
            timeZone: timeZone,
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };

        const formattedTimeSlot =
            new Intl.DateTimeFormat('fr-FR', options).format(date) +
            ' - ' +
            new Intl.DateTimeFormat('fr-FR', options).format(dateEnd);

        return formattedTimeSlot;
    };

    // Determine whether the current time is within the cancellation period.
    // This function does arithmetic on the raw date values (which are in UTC).
    const parseTimeCancellationPeriodEndBooking = (dateString, cancellationPolicy) => {
        const timeZone = 'Europe/Paris';

        // Convertir la date de réservation au fuseau horaire de Paris
        const bookingDate = toZonedTime(new Date(dateString), timeZone);


        // Calculer la date limite d'annulation en fonction de la politique
        let cancellationDeadline;
        if (cancellationPolicy === "72h") {
            cancellationDeadline = new Date(bookingDate.getTime() - 72 * 60 * 60 * 1000);
        } else if (cancellationPolicy === "48h") {
            cancellationDeadline = new Date(bookingDate.getTime() - 48 * 60 * 60 * 1000);
        } else if (cancellationPolicy === "24h") {
            cancellationDeadline = new Date(bookingDate.getTime() - 24 * 60 * 60 * 1000);
        } else {
            // Par défaut, aucune restriction (la date limite est la date de réservation elle-même)
            cancellationDeadline = new Date(bookingDate);
        }

        // Obtenir l'heure actuelle au fuseau horaire de Paris
        const nowInParis = toZonedTime(new Date(), timeZone);

        // Comparer pour voir si la date limite d'annulation est dépassée
        return nowInParis >= cancellationDeadline;
    };

    const parseNotificationsDate = (createdAt) => {
        const timeZone = 'Europe/Paris';
        const date = toZonedTime(new Date(createdAt), timeZone);

        // S'assurer que createdAt est une chaîne de caractères
        if (!createdAt || typeof createdAt !== 'string') {
            return 'Date inconnue';
        }

        try {
            // Obtenir l'heure actuelle à Paris
            const nowParis = toZonedTime(new Date(), timeZone);

            // Calculer les différences de temps
            const diffMins = differenceInMinutes(nowParis, date);
            const diffHours = differenceInHours(nowParis, date);
            const diffDays = differenceInDays(nowParis, date);
            const diffMonths = differenceInMonths(nowParis, date);

            // Formatage du message en fonction du temps écoulé
            if (diffMins < 1) {
                return 'Reçu à l\'instant';
            } else if (diffMins < 60) {
                return `Reçu il y a ${diffMins} min`;
            } else if (diffHours < 24) {
                return `Reçu il y a ${diffHours} h`;
            } else if (diffDays < 30) {
                return `Reçu il y a ${diffDays} jour${diffDays > 1 ? 's' : ''}`;
            } else {
                return `Reçu il y a ${diffMonths} mois`;
            }



        } catch (error) {
            console.error('Erreur lors du traitement de la date:', error);
            return 'Date invalide';
        }
    };

    return {
        parseDate,
        parseBookingDate,
        parseBookingDateDay,
        parseSlotTimeBooking,
        parseTimeCancellationPeriodEndBooking,
        parseNotificationsDate
    };
};

export default useParseDate;