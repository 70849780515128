import {deleteRequest, getRequest, patchRequest, postRequestFormData, postRequestJson} from '../request/apiRequest';


const ActivitiesTypesServices = {
    getActivitiesTypes: async () => {
        return await getRequest('/activity/type/');
    },

    getActivitiesTypeByID: async (typeId) => {
        return await getRequest(`/activity/type/${typeId}`);
    },

    createActivitiesType: async (data) => {
        return await postRequestJson('/activity/type/', data);
    },

    updateActivitiesType: async (typeId, data) => {
        return await patchRequest(`/activity/type/update/${typeId}`, data);
    },

    deleteActivitiesType: async (typeId) => {
        return await deleteRequest(`/activity/type/${typeId}`);
    },

    createOrUpdateTypeCover: async (typeId, data) => {
        return await postRequestFormData(`/activity/type/create-cover-image/${typeId}`, data);
    },

    deleteTypeCover: async (typeId, imageFiles) => {
        return await deleteRequest(`/activity/type/delete-cover-image/${typeId}/${imageFiles}`);
    }
}

export default ActivitiesTypesServices;