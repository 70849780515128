import React, { useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import close from "../../../assets/icones/arrows/right_chevron.svg";
import "../../../styles/views/bookingsSidebar.css";
import Button from "../../../components/button/button";
import useParseDate from "../../../hooks/useParseDate";
import { ErrorContext } from "../../../context/errorContext";
import useStatusName from "../../../hooks/parseText/useStatusName";
import Tag from "../../../components/tag/tag";
import IconButton from "../../../components/button/iconButton";
import recipe from "../../../assets/icones/global/receipt-check.svg";
import eye from "../../../assets/icones/global/eye_white.svg";

const BookingBottomUserDetailsResponsive = ({
                                                isOpen,
                                                closeModal,
                                                booking,
                                                setBookingToModify,
                                                setOpenManageSchedule,
                                                setCurrentBookingSelected,
                                                setOpenAcceptModal,
                                                setOpenDeleteModal
                                            }) => {
    const { showError } = useContext(ErrorContext);
    const { activity, booking: bookingDetails } = booking;

    const currentTime = new Date();
    const bookingStartTime = new Date(new Date(bookingDetails.bookingDate).getTime() - 2 * 60 * 60 * 1000);
    const bookingEndTime = new Date(bookingStartTime.getTime() + bookingDetails.bookingDuration * 60000);
    const isActivityInProgress = bookingStartTime <= currentTime && currentTime <= bookingEndTime;

    const { parseSlotTimeBooking, parseBookingDate, parseBookingDateDay, parseTimeCancellationPeriodEndBooking } = useParseDate();
    const { getStatusName } = useStatusName();

    // Determine if the screen is mobile (using a max-width of 991px)
    const mediaQuery = window.matchMedia('(max-width: 991px)').matches;

    // Backdrop animation
    const backdrop = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5, ease: "easeInOut" }
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" }
        }
    };

    // Animation for desktop sidebar (from the right)
    const sidebarDesktop = {
        hidden: { x: '100%' },
        visible: {
            x: '0',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1
            }
        },
        exit: {
            x: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5
            }
        }
    };

    // Animation for mobile sidebar (from the bottom)
    const sidebarMobile = {
        hidden: { y: '100%' },
        visible: {
            y: '0',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1
            }
        },
        exit: {
            y: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5
            }
        }
    };

    const handleClose = () => {
        closeModal(false);
    };

    const handleDownloadVoucherBilling = (identifier) => {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_API_URL}/booking-users/download-voucher-billing/${bookingDetails.bookingAppId}/${identifier.replace("#", "")}`;
        link.setAttribute('download', `facture_${identifier.replace("#", "")}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadBilling = async (identifier, amountTransfered, type) => {
        try {
            const cleanedIdentifier = identifier.replace("#", "");
            const response = await fetch(`${process.env.REACT_APP_API_URL}/booking-stripe/download-billing-report/${activity.partnerID._id}/${cleanedIdentifier}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    type: type,
                    amountOfTransfer: amountTransfered,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to download the billing report.');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `facture_${cleanedIdentifier}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading billing report:', error);
        }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="booking__sidebar__background"
                    onClick={handleClose}
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <motion.div
                        className="booking__sidebar__section"
                        // Prevent clicks inside the modal from closing it
                        onClick={(e) => e.stopPropagation()}
                        variants={mediaQuery ? sidebarMobile : sidebarDesktop}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <div className="booking__sidebar__container">
                            <div className="booking__sidebar__header">
                                <p className="title-20">Réservation</p>
                                <Tag text={getStatusName(bookingDetails.bookingStatus)} status={bookingDetails.bookingStatus}/>
                            </div>

                            <div className="modal__content">
                                <div className="modal__colum">
                                    <p className="text-14 light">Activitée</p>
                                    <p className="text-16 bold">{activity.name}</p>
                                </div>

                                <div className="modal__colum">
                                    <p className="text-14 light">Réservée par</p>
                                    <p className="text-16 bold">
                                        {bookingDetails ? bookingDetails.bookingUser.firstname : ""}{" "}
                                        {bookingDetails ? bookingDetails.bookingUser.lastname : ""}
                                    </p>
                                </div>

                                <div className="modal__colum">
                                    <p className="text-14 light">Créneau</p>
                                    <p className="text-16 bold">{bookingDetails ? parseBookingDate(bookingDetails.bookingDate) : ""}</p>
                                </div>

                                <div className="modal__colum">
                                    <p className="text-14 light">Nombre de personnes</p>
                                    <p className="text-16 bold">{bookingDetails ? bookingDetails.bookingPersonNumber : 0} personne(s)</p>
                                </div>

                                <div className="modal__colum">
                                    <p className="text-14 light">Montant total de la réservation</p>
                                    <p className="text-16 bold">{bookingDetails ? bookingDetails.bookingAmount : 0}€</p>
                                </div>

                                <div className="modal__colum">
                                    <p className="text-14 light">Détails de la réservation</p>
                                    <div className="modal__colum__details">
                                        {bookingDetails &&
                                            bookingDetails.bookingPricingDetails.map((detail, index) => (
                                                <div key={index} className="modal__row__details__item">
                                                    <div className="modal__colum__details__item">
                                                        <p className="text-16">{detail.pricingQuantity}x</p>
                                                        <p className="text-16">{detail.pricingName}</p>
                                                    </div>
                                                    <p className="text-16 bold">{detail.pricingAmount}€</p>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                                {
                                    !['in-progress', 'cancelled', 'pastDate', "cancelledForReason", "user-cancelled"].includes(bookingDetails.bookingStatus) &&
                                    <div className="booking__sidebar__content">
                                        <div className="booking__sidebar__content__group">
                                            <p className="title-18 bold">Les coordonnées du client</p>
                                            <p className="text-14">
                                                Les coordonnées du client vous seront
                                                transmises {activity.details.cancellationPolicy} avant l'activité,
                                                lorsque le délai d'annulation sera écoulé.
                                            </p>
                                        </div>
                                        <div
                                            className={`booking__sidebar__content__group__border ${parseTimeCancellationPeriodEndBooking(bookingDetails.bookingDate, activity.details.cancellationPolicy) ? "" : "booking__sidebar__content__blurred"}`}>
                                            <p className="title-16 bold">Contact
                                                de {bookingDetails.bookingUser.firstname} :</p>
                                            <div className="booking__sidebar__content__group__row">
                                                <p className="text-16">
                                                    <a href={`tel:${bookingDetails.bookingUser.phoneNumber}`}>
                                                <span className="text-16">
                                                    {parseTimeCancellationPeriodEndBooking(bookingDetails.bookingDate, activity.details.cancellationPolicy)
                                                        ? bookingDetails.bookingUser.phoneNumber
                                                        : "pas encore disponible"}
                                                </span>
                                                    </a>
                                                </p>
                                                <p className="text-16">
                                                    <a href={`mailto:${bookingDetails.bookingUser.email}`}>
                                                <span className="text-16">
                                                    {parseTimeCancellationPeriodEndBooking(bookingDetails.bookingDate, activity.details.cancellationPolicy)
                                                        ? bookingDetails.bookingUser.email
                                                        : "pas encore disponible"}
                                                </span>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {
                            !['cancelled', 'pastDate', "cancelledForReason", "user-cancelled"].includes(bookingDetails.bookingStatus) &&
                            <div className="booking__sidebar__footer">
                                {bookingDetails.bookingStatus === "in-progress" ? (
                                    <div className="modal__actions">
                                        <Button
                                            text={"Accepter"}
                                            isPrimary={true}
                                            onClick={() => {
                                                setCurrentBookingSelected(bookingDetails._id);
                                                closeModal();
                                                setOpenAcceptModal(true);
                                            }}
                                        />
                                        <Button
                                            text={"Refuser"}
                                            isPrimary={false}
                                            onClick={() => {
                                                setCurrentBookingSelected(bookingDetails._id);
                                                closeModal();
                                                setOpenDeleteModal({
                                                    isOpen: true,
                                                    status: "cancelled",
                                                    text: "Refuser la réservation",
                                                    button: "Refuser la réservation",
                                                    description: "En refusant cette réservation, cela pourrait impacter la satisfaction de l'utilisateur et votre réputation chez Frimity.",
                                                    booking: bookingDetails
                                                });
                                            }}
                                        />
                                        <p
                                            className="text-14 text_grey text_bold pointer"
                                            onClick={() => {
                                                setBookingToModify({
                                                    activity: activity,
                                                    booking: bookingDetails
                                                });
                                                closeModal();
                                                setOpenManageSchedule(true);
                                            }}
                                        >
                                            Autre créneau
                                        </p>
                                    </div>
                                ) : (
                                    <div className="booking__sidebar__row-start">
                                        <IconButton
                                            icon={recipe}
                                            text={"Bon de réservation"}
                                            onClick={() => handleDownloadVoucherBilling(bookingDetails.bookingIdentifier)}
                                        />
                                        {['in-progress', 'booked', 'waiting-date'].includes(bookingDetails.bookingStatus) && !isActivityInProgress && (
                                            <Button text={"Annuler la réservation"} isRed={true}
                                                    onClick={() => {
                                                        setCurrentBookingSelected(bookingDetails._id);
                                                        closeModal();
                                                        setOpenDeleteModal({
                                                            isOpen: true,
                                                            status: "cancelledForReason",
                                                            text: "Annuler la réservation",
                                                            button: "Annuler la réservation",
                                                            description: "En annulant cette réservation, veuillez noter que cela pourrait impacter la satisfaction de l'utilisateur et votre réputation au sein de la communauté Frimity.",
                                                            booking: bookingDetails
                                                        });
                                                    }} isFull={true}/>
                                        )}
                                        {bookingDetails.bookingStatus === "completed" && bookingDetails.stripeTransfer && (
                                            <IconButton
                                                icon={eye}
                                                text={"Voir la facture"}
                                                onClick={() => handleDownloadBilling(
                                                    bookingDetails.bookingIdentifier,
                                                    bookingDetails.stripeTransfer.stripeTransferAmount,
                                                    bookingDetails.stripeTransfer.stripeTransferType
                                                )}
                                                isDark={true}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        }

                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default BookingBottomUserDetailsResponsive;