import logo from "../../assets/images/svg/logo_row.svg";
import React, {useState, useEffect} from "react";
import {useParams} from "react-router";
import {useLoader} from "../../context/loaderContext";
import PartnersServices from "../../api/services/partners";
import "../../styles/views/partnerPublicView.css";
import facebook from "../../assets/images/png/facebook_icon.png";
import instagram from "../../assets/images/png/instagram_icon.png";
import linkedin from "../../assets/images/png/linkedin_icon.png";
import tiktok from "../../assets/images/png/tiktok_icon.png";
import ActivitiesServices from "../../api/services/activities";
import Location from '../../assets/icones/global/location_grey.svg';
import {formatAddress} from "../../utils/activityParsing";
import appleBadge from "../../assets/images/svg/apple_badge.svg";
import googleBadge from "../../assets/images/svg/google_badge.svg";

const PartnerPublicView = () => {
    const { partnerId } = useParams();
    const { setLoading } = useLoader();
    const [partnerInfos, setPartnerInfos] = useState(null);
    const [partnerActivities, setPartnerActivities] = useState([]);


    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            try {
                // Pass `true` to force the public token for this API call
                const response = await PartnersServices.getPartnerById(partnerId, true);
                if (response.status === 200) {
                    setPartnerInfos(response.data);
                }

                const responseActivities = await ActivitiesServices.getPublicPartnerActivities(partnerId, true);
                if (responseActivities.status === 200) {
                    setPartnerActivities(responseActivities.data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [partnerId, setLoading]);


    return(
        <div className="container-partner">
            {/* Logo / Header */}
            <img className="logo" src={logo} alt="logo"/>

            <div className="partner-main-infos">
                <div className="partner-main-logo logo">
                    {
                        partnerInfos?.partnerSocialNetworksID?.logo?.url ?
                            <img src={partnerInfos?.partnerSocialNetworksID?.logo?.url} alt="logo"/> :
                            <div className="big_icon_logo"></div>
                    }
                </div>

                <div className="partner-main-infos infos">
                    <p className="text-16">{partnerInfos?.partnerSocialNetworksID?.description}</p>
                </div>

                <div className="partner-main-infos social-media">
                    {
                        partnerInfos?.partnerSocialNetworksID?.socialNetworks.map((socialNetwork, index) => (
                            <a key={index} href={socialNetwork.url} target="_blank" rel="noreferrer">
                                <img src={
                                    socialNetwork.type === 'facebook' ? facebook :
                                        socialNetwork.type === 'instagram' ? instagram :
                                            socialNetwork.type === 'linkedin' ? linkedin : tiktok
                                } alt={socialNetwork.type}/>
                            </a>
                        ))
                    }
                </div>
            </div>

            <div className="partner-activities">
                <div className="partner-activities-title">
                    <h1 className="title-20">Activitée(s) :</h1>
                    <p className="text-16">{partnerActivities?.length} activitée{partnerActivities?.length > 1 ? 's' : ''}</p>
                </div>

                <div className="partner-activities-list">
                    {
                        partnerActivities?.map((activity, index) => (
                            <div key={index} className="activity-card">
                                <div className={`activity-card-image`}>
                                    {
                                        activity?.firstImage ? <img src={activity?.firstImage.url} alt="activity"/> :
                                            <div className="big_icon">{activity?.activityType.split(' ')[0]}</div>
                                    }
                                </div>
                                <div className="activity-card-infos">
                                    <div className={"type-tag"}>{activity?.activityType}</div>
                                    <div className={"container-text"}>
                                        <h2 className="title-16">{activity?.name.length > 20 ? activity?.name.substring(0, 20) + '...' : activity?.name}</h2>

                                        <div className={"location"}>
                                            <img src={Location} alt="logo"/>
                                            <p className="text-14">{formatAddress(activity?.townLocation).city}</p>
                                        </div>

                                        <p className="text-14">À partir de <strong>{activity?.firstPrice}€</strong></p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>


            <div className="preview-footer">
                <p className={"text-18 bold"}>Télécharge maintenant Frimity</p>
                <div className="footer-links">
                    <a href={process.env.REACT_APP_APP_APPLE_URL} className={"badge"}>
                        <img src={appleBadge} alt="apple-badge"/>
                    </a>
                    <a href={process.env.REACT_APP_APP_ANDROID_URL} className={"badge"}>
                        <img src={googleBadge} alt="google-badge"/>
                    </a>
                </div>
            </div>
        </div>
    )

}

export default PartnerPublicView;