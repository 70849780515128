import React, {useContext, useEffect, useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import close from "../../../assets/icones/arrows/right_chevron.svg";
import "../../../styles/views/bookingsSidebar.css";
import Button from "../../../components/button/button";
import BookingTimeSlotsServices from "../../../api/services/bookingTimeSlots";
import { filterBookingTimeSlotsForSingleActivity } from "../../../hooks/parseBooking/useBookingTimeSlots";
import timer from "../../../assets/icones/global/time_circle.svg";
import calendar from "../../../assets/icones/global/calendar.svg";
import group from "../../../assets/icones/global/users.svg";
import useParseDate from "../../../hooks/useParseDate";
import BookingUsersService from "../../../api/services/bookingUsers";
import {ErrorContext} from "../../../context/errorContext";
import Checkbox from "../../../components/form/inputCheckbox/checkbox";
import Tag from "../../../components/tag/tag";
import useStatusName from "../../../hooks/parseText/useStatusName";
import moment from "moment-timezone";

const BookingManageSchedule = ({ open, setOpen, bookingToModify, setRefresh }) => {

    const { showError } = useContext(ErrorContext);
    const handleClose = () => {
        setOpen(false);
    };

    const {parseBookingDate} = useParseDate();
    const { getStatusName } = useStatusName();
    const [bookingExchangeTimeSlots, setBookingExchangeTimeSlots] = useState(null);
    const [slotSelected, setSlotSelected] = useState(null);
    const mediaQuery = window.matchMedia('(max-width: 991px)').matches;


    useEffect(() => {
        if(bookingToModify && bookingToModify.activity){
            BookingTimeSlotsServices.getBookingTimeSlotByActivityId(bookingToModify.activity._id)
            .then((response) => {
                if(response.status === 200){
                    setBookingExchangeTimeSlots(filterBookingTimeSlotsForSingleActivity(response.data, bookingToModify.booking.bookingDate));
                }
            }).catch((error) => {
                console.log(error);
            });
        }

    }, [bookingToModify]);


    const handleUpdateBooking = async (hour) => {
        try{
            if(!hour){
                throw new Error("Veuillez sélectionner un créneau horaire");
            }

            const moment = require('moment-timezone');

            // Extract the start time from the input (e.g., "10h30 - 11h30")
            let startHour = hour.split(" - ")[0].trim();

            // Replace "h" with ":" to normalize the time string
            startHour = startHour.replace("h", ":");

            // Parse hours and minutes
            const [hours, minutes] = startHour.split(":").map(Number);

            // Create a moment object in the Paris timezone
            let updatedMoment = moment.tz(bookingToModify.booking.bookingDate, "Europe/Paris");

            // Set the new time (use keys 'hour' and 'minute')
            updatedMoment.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

            // Convert the moment object to a Date (if your API requires a Date object)
            let finalDate = updatedMoment.toDate();


           const response = await BookingUsersService.updateBookingUser(bookingToModify.booking._id, {
               bookingStatus: "waiting-date",
               bookingDate: finalDate // Correctly adjusted Date object
           })

            if(response.status === 200){
                setRefresh(true);
                handleClose();
            }
        }
        catch (error){
            showError(error);
        }
    };


    const backdrop = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5, ease: "easeInOut" } // Smooth easing for fade
        },
        exit: {
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" }
        }
    };

    const sidebarDesktop = {
        hidden: { x: '100%' },
        visible: {
            x: '0',
            transition: {
                type: 'spring',
                stiffness: 120,  // Lower stiffness makes it less rigid
                damping: 20,     // Lower damping makes it more bouncy
                mass: 1          // Mass affects the bounciness; increase for more lethargic movement
            }
        },
        exit: {
            x: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5  // Optional if you want to explicitly control duration on hiding
            }
        }
    };

    const sidebarMobile = {
        //from bottom
        hidden: { y: '100%' },
        visible: {
            y: '0',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1
            }
        },
        exit: {
            y: '100%',
            transition: {
                type: 'spring',
                stiffness: 120,
                damping: 20,
                mass: 1,
                duration: 0.5
            }
        }
    }


    return  !mediaQuery ? (
        <AnimatePresence>
            {open && (
                <motion.div className="booking__sidebar__background"
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="exit">
                    <motion.div className="booking__sidebar__section"
                                variants={sidebarDesktop}
                                initial="hidden"
                                animate="visible"
                                exit="exit">
                        <div className="booking__sidebar__close">
                            <img src={close} alt="close" onClick={handleClose}/>
                        </div>
                        <div className="booking__sidebar__container">
                            <div className="booking__sidebar__header">
                                <p className="text-20 bold">Proposer une modification sur cette réservation</p>
                            </div>

                            <div className="booking__sidebar__body">
                                <div className="booking__sidebar__form">
                                    <div className="sidebar__form__container__group">
                                        <p className="text-14">Activité</p>
                                        <div className={"form__input"}><p className="text-16">{bookingToModify.activity.name}</p></div>
                                    </div>

                                    <div className="sidebar__form__container__group">
                                        <div className="sidebar__form__container__head">
                                            <p className="text-16 bold">Autres créneau disponible</p>
                                            <p className="text-14">Sélectionnez les créneau ci-dessous qui sont actuellement
                                                disponible.</p>
                                        </div>

                                        {
                                            bookingExchangeTimeSlots ?
                                            <div className={"sidebar__time-slot__schedule"}>
                                                <div className="sidebar__time-slot__container__schedule">
                                                    <p className="text-16">Créneau actuellement disponible</p>

                                                    <div className={"time-slot__container__wrapper smaller"}>
                                                        {bookingExchangeTimeSlots.timeSlots.map(slot => (
                                                            <Checkbox
                                                                key={slot._id}
                                                                label={slot}
                                                                icon={timer}
                                                                text={slot}
                                                                onChange={() => setSlotSelected(slot)}
                                                                value={slotSelected === slot}
                                                                isChecked={slotSelected === slot}
                                                                name={"timeSlot"}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="sidebar__time-slot__container__footer">
                                                    <p className="text-14">Détail de la réservation :</p>
                                                    <div className={"table__booking__infos"}>
                                                        <img src={calendar} alt={"person"}/>
                                                        <p className={"text-14 text_grey"}>{parseBookingDate(bookingToModify.booking.bookingDate)}</p>
                                                    </div>
                                                    <div className={"table__booking__separation_line"}/>
                                                    <div className={"table__booking__infos"}>
                                                        <img src={group} alt={"person"}/>
                                                        <p className={"text-14 text_grey"}>{bookingToModify.booking.bookingPersonNumber} {bookingToModify.booking.bookingPersonNumber > 1 ? "personnes" : "personne"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                                :
                                                <div className={"container__empty"}>
                                                    <p className={"text-14"}>Vous n'avez pas encore de créneau horaire
                                                        pour cette date</p>
                                                    <Button text={"Ouvrir des dates"} isPrimary={true}
                                                            link={`/partners/${bookingToModify.activity.partnerID._id}/bookings/schedule`}/>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="booking__sidebar__actions">
                                    <Button text={"Proposer ce créneau"} isPrimary={true} onClick={() => handleUpdateBooking(slotSelected)} isFull={true}/>
                                    <Button text={"Annuler"} isPrimary={false} onClick={handleClose} isFull={true}/>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    ) : (
        <AnimatePresence>
            {open && (
                <motion.div className="booking__sidebar__background"
                            variants={backdrop}
                            initial="hidden"
                            animate="visible"
                            exit="exit">
                    <motion.div className="booking__sidebar__section"
                                variants={sidebarMobile}
                                initial="hidden"
                                animate="visible"
                                exit="exit">
                        <div className="booking__sidebar__container">
                            <div className="booking__sidebar__header">
                                <p className="text-20 bold">Proposer une modification</p>
                                <Tag text={getStatusName("in-progress")} status={"in-progress"}/>
                            </div>

                            <div className="booking__sidebar__body">
                                <div className="booking__sidebar__form">

                                    <div className="sidebar__form__container__group">
                                        <div className="sidebar__form__container__head">
                                            <p className="text-16 grey">Autres créneau disponible</p>
                                            <p className="text-14">Sélectionnez les créneau ci-dessous qui sont actuellement
                                                disponible.</p>
                                        </div>

                                        {
                                            bookingExchangeTimeSlots ?
                                                <div className={"sidebar__time-slot__schedule"}>
                                                    <div className="sidebar__time-slot__container__schedule">
                                                        <p className="text-16">Créneau actuellement disponible</p>

                                                        <div className={"time-slot__container__wrapper smaller"}>
                                                            {bookingExchangeTimeSlots.timeSlots.map(slot => (
                                                                <Checkbox
                                                                    key={slot._id}
                                                                    label={slot}
                                                                    icon={timer}
                                                                    text={slot}
                                                                    onChange={() => setSlotSelected(slot)}
                                                                    value={slotSelected === slot}
                                                                    isChecked={slotSelected === slot}
                                                                    name={"timeSlot"}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className={"container__empty"}>
                                                    <p className={"text-14"}>Vous n'avez pas encore de créneau horaire
                                                        pour cette date</p>
                                                    <Button text={"Ouvrir des dates"} isPrimary={true}
                                                            link={`/partners/${bookingToModify.activity.partnerID._id}/bookings/schedule`}/>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="booking__sidebar__actions">
                                    <Button text={"Proposer"} isPrimary={true} onClick={() => handleUpdateBooking(slotSelected)} isFull={true}/>
                                    <Button text={"Annuler"} isPrimary={false} onClick={handleClose} isFull={true}/>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    )
};

export default BookingManageSchedule;
