import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import React, {useContext} from "react";
import {jwtDecode} from "jwt-decode";
import {ErrorContext} from "../context/errorContext";
import {usePartner} from "../context/partnerContext";
import IconButton from "../components/button/iconButton";
import stripe from "../assets/icones/global/check_stroke.svg";
import BookingPaymentServices from "../api/services/bookingPayment";
import {useLoader} from "../context/loaderContext";

const useUserRole = () => {

    // Validate token expiration
    const checkToken = (token) => {
        if (!token) return false;

        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decodedToken.exp >= currentTime;
        } catch (error) {
            console.error("Error decoding token:", error);
            return false;
        }
    };

    const useAuthState = () => {
        const user = useSelector((state) => state.auth.user);
        const token = useSelector((state) => state.auth.token);
        const isTokenValid = checkToken(token);
        return { user, token, isTokenValid };
    };

    // Retrieve the current partner based on role and context
    const getCurrentPartner = (user, currentPartnerId, allPartners) => {
        if (user.role === "super-admin") {
            return allPartners.find((partner) => partner._id === currentPartnerId) || null;
        }
        return user?.partnerID?.find((partner) => partner._id === currentPartnerId) || null;
    };

    // Utility functions for partner validations
    const isPartnerVerified = (partner) => !!partner?.isVerified;
    const hasValidSubscription = (partner) => !!partner?.subscriptionID;
    const hasValidBooking = (partner) => !!partner?.bookingPartnersID;

    const hasCompleteStripeAccount = (partner) => !!partner?.subscriptionID?.isStripeCompleted;


    // Guards for specific roles and features
    const PartnerRouteGuard = ({ user, isTokenValid, allPartners }) => {
        const { currentPartnerId } = usePartner();

        if (!isTokenValid) return false;

        if (["admin", "super-admin"].includes(user.role)) return true;

        const currentPartner = getCurrentPartner(user, currentPartnerId, allPartners);

        return user.role === "partners-admin" || user.role === "partner"
            ? isPartnerVerified(currentPartner)
            : false;
    };

    const AdminRouteGuard = ({ user, isTokenValid }) =>
        isTokenValid && ["admin", "super-admin"].includes(user.role);

    const PartnerEmailVerifiedRouteGuard = ({ user, isTokenValid }) => {
        if (!isTokenValid || !user.isEmailVerified) return false;

        if (["admin", "super-admin"].includes(user.role)) return true;

        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);
        return isPartnerVerified(currentPartner);
    };

    const PartnerSubscriptionRouteGuard = ({ user, isTokenValid, allPartners }) => {
        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId, allPartners);
        return isTokenValid && hasValidSubscription(currentPartner);
    };

    const PartnerBookingRouteGuard = ({ user, isTokenValid }) => {
        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);
        return isTokenValid && hasValidBooking(currentPartner);
    };

    const PartnerAdminRouteGuard = ({ user, isTokenValid }) => {
        const partnerVerified = user.partnerID.map((partner) => partner.isVerified);
        return isTokenValid && user.role === "partners-admin" && partnerVerified.every(Boolean);
    };

    // Route components
    const PrivateRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        const { allPartners } = usePartner();
        const { showError } = useContext(ErrorContext);

        if (!isTokenValid) {
            showError("Invalid or expired token");
            return <Navigate to="/login" />;
        }

        if (["admin", "super-admin"].includes(user.role)) return element;

        if (user.role === "partners-admin" && (window.location.pathname.includes("settings") || window.location.pathname.includes("support"))) {
            return element;
        }

        if (!PartnerRouteGuard({ user, isTokenValid, allPartners })) {
            showError("Not verified yet");
            return <Navigate to="/error" />;
        }

        if (!PartnerSubscriptionRouteGuard({ user, isTokenValid, allPartners })) {
            return <Navigate to="/stripe-payment" />;
        }

        return element;
    };

    const AdminRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        return AdminRouteGuard({ user, isTokenValid }) ? element : <Navigate to="/login" />;
    };

    const PartnerNoSubscriptionRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        const { currentPartnerId } = usePartner();
        const currentPartner = getCurrentPartner(user, currentPartnerId);

        if (!currentPartner) return <Navigate to="/error" />;
        return PartnerSubscriptionRouteGuard({ user, isTokenValid })
            ? <Navigate to={`/partners/${currentPartnerId}`} />
            : element;
    };

    const PartnerBookingRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        const { currentPartnerId, allPartners } = usePartner();
        const { showError } = useContext(ErrorContext);
        const { setLoading } = useLoader();

        const currentPartner = getCurrentPartner(user, currentPartnerId, allPartners);

        if (!currentPartner) return <Navigate to="/error" />;
        if (!isTokenValid) {
            showError("Invalid or expired token");
            return <Navigate to="/login" />;
        }


        // Check if Stripe account is incomplete
        if (!hasCompleteStripeAccount(currentPartner)) {

            const handleUpdateStripe = () => {
                setLoading(true);
                BookingPaymentServices.updateConnectAccount({
                    connectedAccountId: currentPartner.subscriptionID.connectedAccountId,
                    partnerID: currentPartnerId,
                })
                .then((response) => {
                        window.location.href = response.data.url;
                })
                .catch((error) => {
                    showError(error);
                    setLoading(false);
                });
            };

            return (
                <div className={"stripe-nocomplete"}>
                    <h2 className={"title-23 center"}>Votre compte Stripe n'est pas complet</h2>
                    <p className={"text-16 center"}>Vous devez compléter votre compte Stripe pour continuer et accéder à vos réservations ainsi que vos plages horaires.</p>

                    <IconButton isPrimary={true} onClick={handleUpdateStripe} icon={stripe} text={"Compléter mon compte Stripe"} />
                </div>
            );
        }

        return hasValidBooking(currentPartner)
            ? element
            : <Navigate to={`/partners/${currentPartnerId}/bookings/no-booking`} />;
    };

    const PartnerAdminRoute = ({ element }) => {
        const { user, isTokenValid } = useAuthState();
        return PartnerAdminRouteGuard({ user, isTokenValid }) ? element : <Navigate to="/login" />;
    };

    const MainRouteSignIn = ({ element }) => {
        const isEmailVerified = useSelector((state) => state.signIn.user?.isEmailVerified);
        return isEmailVerified ? element : <Navigate to="/signin/verification/" />;
    };

    const VerificationEmailSignInRoute = ({ element }) => {
        const isEmailVerified = useSelector((state) => state.signIn.user?.isEmailVerified);
        return !isEmailVerified ? element : <Navigate to="/signin" />;
    };

    const VerificationUserRoute = ({ element }) => {
        const isEmailVerified = useSelector((state) => state.auth.user?.isEmailVerified);
        const hasTemporaryPassword = useSelector((state) => state.auth.user?.hasTemporaryPassword);
        return !isEmailVerified || hasTemporaryPassword ? element : <Navigate to="/login" />;
    };

    return {
        PrivateRoute,
        AdminRoute,
        MainRouteSignIn,
        VerificationEmailSignInRoute,
        VerificationUserRoute,
        PartnerNoSubscriptionRoute,
        PartnerAdminRoute,
        PartnerBookingRoute,
    };
};

export default useUserRole;
