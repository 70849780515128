import React, {useEffect, useState} from "react";
import closeIcon from "../../../assets/icones/global/close-stroke.svg";
import Button from "../../button/button";
import Input from "../../form/inputText/input";
import InputCoverImage from "../../form/inputFile/InputCoverImage";
import InputSelect from "../../form/inputSelect/inputSelect";

const ModalAddTypeActivity = ({
                                  isOpen,
                                  closeModal,
                                  addType,
                                  editType,
                                  text,
                                  categories
                              }) => {
    const [typeName, setTypeName] = useState(editType ? editType.name : "");
    const [selectedCategory, setSelectedCategory] = useState(
        editType && editType.parentCategory ? editType.parentCategory._id : ""
    );
    const [coverImage, setCoverImage] = useState(null);

    useEffect(() => {
        if (editType) {
            setTypeName(editType.name || "");
            setSelectedCategory(editType.parentCategory ? editType.parentCategory._id : "");
            setCoverImage(editType.icon ? { preview: editType.icon } : null);
        } else {
            setTypeName("");
            setSelectedCategory("");
            setCoverImage(null);
        }
    }, [editType, isOpen]);

    const handleSend = () => {
        if (!typeName || !typeName.trim()) {
            console.error("Le nom est vide. Veuillez saisir un nom.");
            return;
        }
        addType({
            name: typeName,
            parentCategory: selectedCategory,
            icon: coverImage ? coverImage.file : null,
        });

        setTypeName("");
        setSelectedCategory("");
        setCoverImage(null);
        closeModal();
    };

    // Transform incoming categories into the format expected by InputSelect:
    // Each option must have { _id, value, name }.
    const selectOptions = categories.map((category) => ({
        _id: category._id,
        value: category._id,
        name: category.name,
    }));


    return (
        <div className={`bg__modal ${isOpen ? "open" : ""}`}>
            <div className={`modal ${isOpen ? "open" : ""} addActivity`}>
                <div className="modal__header">
                    <div className="modal__header__text">
                        <p className="title-14">{text}</p>
                    </div>
                    <img
                        src={closeIcon}
                        alt="close"
                        onClick={closeModal}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="modal__content">
                    <InputCoverImage name="coverImage" onChange={setCoverImage} editType={editType} />
                    <Input
                        name="typeName"
                        type="text"
                        label="Nom de la catégorie"
                        valueInput={typeName}
                        onChangeForm={(e) => setTypeName(e.target.value)}
                        placeholder="Ex : 🛹 Skateboard"
                        isColumn={true}
                    />
                    <InputSelect
                        label="Catégorie parente"
                        name="parentCategory"
                        valueSelected={selectedCategory}
                        placeholder="Sélectionner..."
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        options={selectOptions}
                        isColumn={true}
                    />
                    <div className="modal__content__actions">
                        <Button text="Annuler" isPrimary={false} onClick={closeModal} />
                        <Button text="Envoyer" isPrimary={true} onClick={handleSend} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddTypeActivity;