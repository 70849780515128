import React, {useEffect, useState} from "react";
import IconButton from "../../button/iconButton";
import trash from "../../../assets/icones/global/trash_can_red.svg";
import importFile from "../../../assets/icones/global/import.svg";
import ModalImagePreview from "../../modal/modalImagePreview";
import ActivitiesTypesServices from "../../../api/services/activitiesTypes";

// This component is modeled after your InputImages component but adapted for a single image.
const InputCoverImage = ({ name, onChange, error, editType }) => {
    const [coverImage, setCoverImage] = useState(null);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        // Initialize with existing cover image if present in editType
        if (editType && editType.coverImage) {
            setCoverImage({ preview: editType.coverImage });
        } else {
            setCoverImage(null);
        }
    }, [editType]);

    // Handle file selection for a single cover image.
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];

            const previewUrl = URL.createObjectURL(file);
            const newImage = { file, preview: previewUrl };

            setCoverImage(newImage);
            if (onChange) {
                onChange(newImage);
            }
        }
    };

    // Delete the currently selected image.
    const handleDeleteImage = async () => {
        // If it's an existing image on the server (editType has an ID and coverImage)
        if (editType && editType._id && editType.coverImage) {
            try {
                setIsDeleting(true);
                // Extract filename from the URL
                const fileName = editType.coverMetadata.metadata.sanitizedName;

                const response = await ActivitiesTypesServices.deleteTypeCover(editType._id, fileName);

                if (response.status === 200) {
                    setCoverImage(null);
                    if (onChange) {
                        onChange(null);
                    }
                    // Update the editType object
                    if (editType) {
                        editType.coverImage = null;
                    }
                }
            } catch (error) {
                console.error("Error deleting cover image:", error);
                alert("Une erreur est survenue lors de la suppression de l'image.");
            } finally {
                setIsDeleting(false);
            }
        } else {
            // If it's a newly selected image (not saved to server yet)
            setCoverImage(null);
            if (onChange) {
                onChange(null);
            }
        }
    };

    return (
        <div className={`input__files__container ${error ? "errorImg" : ""}`}>

            {
                !coverImage ?
                <div className={"form__col col__files"}>
                    <div className={"input__files__custom"}>
                        <img src={importFile} alt="Importer une image"/>
                        <div className="input__files__custom__text">
                           <p className="text-14">
                                <span className="text-14 bold">Cliquez</span> ou glisser-déposer
                            </p>
                            <span className="text-14">PNG, JPG, WEBP (max 10 Mo)</span>
                        </div>
                        <input
                            type="file"
                            name={name}
                            onChange={handleFileChange}
                            accept="image/png, image/jpeg, image/webp"
                        />
                    </div>
                </div>

                :
                <div className="files-added">
                    <ModalImagePreview
                        isOpen={isPreviewModalOpen}
                        closeModal={() => setIsPreviewModalOpen(false)}
                        image={coverImage ? coverImage.preview : ""}
                    />
                    <div className="files-image-preview">
                        <img
                            src={coverImage.preview}
                            alt="Prévisualisation"
                            style={{maxWidth: "200px"}}
                        />
                        <div className="cover-image-actions">
                            <IconButton
                                icon={trash}
                                onClick={() => handleDeleteImage()}
                            />
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

export default InputCoverImage;
